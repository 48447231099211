import React from 'react';

export const Debug = (props: { v: any }) => <pre>{JSON.stringify(props.v, null, ' ')}</pre>;

export const generateBasicToken = (email: string, password: string) =>
  Buffer.from(`${email}:${password}`, 'binary').toString('base64');

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// DOM
export const loadScriptByURL = (id: string, url: string, callback: () => void) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

export const removeScriptById = (id: string) => {
  console.log('removeScriptById');
  const isScriptExist = document.getElementById(id);
  console.log('removeScriptById', isScriptExist);

  if (isScriptExist) {
    const removal = isScriptExist?.parentNode?.removeChild(isScriptExist);
    console.log('removeScriptById', { removal });
    isScriptExist.remove();
  }
};

export const removeElemByClass = (id: string) => {
  console.log('removeScriptById');
  const isScriptExist = document.getElementsByClassName(id)[0];
  console.log('removeScriptById', isScriptExist);

  if (isScriptExist) {
    const removal = isScriptExist?.parentNode?.removeChild(isScriptExist);
    console.log('removeScriptById', { removal });
    isScriptExist.remove();
  }
};

export function findGetParameter(parameterName: string) {
  // alert(window.location);
  let result = null,
    tmp = [];
  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

export function findHashParameter(parameterName: string) {
  let result = null,
    tmp = [];
  tmp = window.location.hash.substr(1).split('=');
  if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  return result;
}

export const getWindowSubpath = () => {
  const fullPath = window.location.pathname;
  return '/' + (fullPath.split('/')[1] || '');
};

export const dateStrToDate = (dateStr: string) => new Date(Date.parse(dateStr));
export const dateStrToLocale = (dateStr: string | null | undefined) =>
  !!dateStr && dateStrToDate(dateStr).toLocaleString();
export const dateStrToLocaleDate = (dateStr: string | null | undefined) =>
  !!dateStr && dateStrToDate(dateStr).toLocaleDateString();

export const diffDays = (date1: Date, date2: Date) => {
  // To calculate the time difference of two dates
  const Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  return Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
};