import React, { useEffect, useState } from 'react';
import { Signup } from '../../pages/Signup/Signup';
import { Login } from '../../pages/Login/Login';
import { useAppDispatch } from '../../hooks/reduxTKHooks';
import { restoreLoginAsync } from '../../redux/signinSlice';
import { routes, sortedPublicRoutes } from '../../pages/routes';
import { getWindowSubpath } from '../../utils';
import { useURLParams } from '../../hooks/useURLParams';

interface Props {}

export interface ExitProps {
  exitRouteFn?: () => void;
}

export const AuthProtector = (p: Props) => {
  // Restore saved credentials at mount
  const dispatch = useAppDispatch();
  const { register } = useURLParams('register');

  const [signupMode, setSignup] = useState(false);
  const [publicRouteKey, setPublicRouteKey] = useState(routes.loading.key);
  useEffect(() => {
    // public // called twice here FIXME
    console.log('Mount - public routing', { register });
    const publicRouteFound = sortedPublicRoutes.find((r: any) => r.path === getWindowSubpath());
    if (publicRouteFound) {
      setPublicRouteKey(publicRouteFound.key);
    } else {
      // logged in ?
      setPublicRouteKey('');
      if (register === '1') {
        setSignup(true);
        return;
      }
      dispatch(restoreLoginAsync());
    }
  }, [dispatch, register]);

  const toggleAuth = () => setSignup((m) => !m);
  const exitRouteFn = () => {
    setPublicRouteKey('');
    // go to root
    window.location.href = '/';
    // history.back();
  };

  const toggleForgotPsw = () => {
    window.location.href = routes.forgotPsw.path;
  };

  if (publicRouteKey) {
    // @ts-ignore
    const Component = routes[publicRouteKey].Component;
    // @ts-ignore
    return <Component exitRouteFn={exitRouteFn} />;
  } else {
    return signupMode ? (
      <Signup toggleAuth={toggleAuth} />
    ) : (
      <Login toggleAuth={toggleAuth} toggleForgotPsw={toggleForgotPsw} />
    );
  }
};
