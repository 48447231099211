import React from 'react';
import { FormCheck, Row, Col } from 'react-bootstrap';
import Feedback from 'react-bootstrap/Feedback';
import appScreen from '../../assets/ionnyk-app4.png';
import iosLogo from '../../assets/btn_app_Store.svg';
import androidLogo from '../../assets/btn_google_play.svg';
import { Link } from 'react-router-dom';
import { routes } from '../../pages/routes';
import { useTranslation } from '../../hooks/useTranslation';

export enum OnboardingStep {
  EMAIL_VALIDATION,
  VOUCHER_PROMPT,
  APP_INSTALLATION,
}

interface Props {
  progress: Record<OnboardingStep, boolean>;
}

export const OnboardingTodo = (props: Props) => {
  const t = useTranslation();

  return (
    <div style={{ padding: '25px' }}>
      <FormCheck type="checkbox" id="customControlAutosizing" custom>
        <FormCheck.Input
          readOnly
          type={'checkbox'}
          isValid
          disabled={props.progress[OnboardingStep.EMAIL_VALIDATION]}
          checked={props.progress[OnboardingStep.EMAIL_VALIDATION]}
        />
        <FormCheck.Label>{t('onboarding_s1_title')}</FormCheck.Label>
        {!props.progress[OnboardingStep.EMAIL_VALIDATION] && (
          <Feedback type="valid">
            {t('onboarding_s1_body')}
            <a
              href="#" // FIXME
              style={{ textDecoration: 'underline', marginLeft: '0.5rem' }}
            >
              {t('resend_btn')}
            </a>
          </Feedback>
        )}
      </FormCheck>
      <br />
      {/*FIXME revamp VOUCHER business wise*/}
      <FormCheck type="checkbox" id="customControlAutosizing" custom>
        <FormCheck.Input
          readOnly
          type={'checkbox'}
          isValid
          disabled={props.progress[OnboardingStep.VOUCHER_PROMPT]}
          checked={props.progress[OnboardingStep.VOUCHER_PROMPT]}
        />
        <FormCheck.Label>{t('onboarding_s2_title')}</FormCheck.Label>
        {!props.progress[OnboardingStep.VOUCHER_PROMPT] && (
          <Feedback type="valid">
            {t('onboarding_s2_body')}
            <br />
            <br />
            <Row>
              <Col>
                <Link to={routes.voucher.path} style={{ textDecoration: 'underline' }}>
                  {t('use_voucher_btn')}
                </Link>
              </Col>
              <Col>
                {/* FIXME add action */}
                <a href="#" style={{ textDecoration: 'underline' }}>
                  {t('skip_btn')}
                </a>
              </Col>
            </Row>
          </Feedback>
        )}
      </FormCheck>
      <br />
      <FormCheck type="checkbox" id="customControlAutosizing" custom>
        <FormCheck.Input
          readOnly
          type={'checkbox'}
          isValid
          disabled={props.progress[OnboardingStep.APP_INSTALLATION]}
          checked={props.progress[OnboardingStep.APP_INSTALLATION]}
        />
        <FormCheck.Label>{t('onboarding_s3_title')}</FormCheck.Label>
        {!props.progress[OnboardingStep.APP_INSTALLATION] && (
          <Feedback type="valid">
            <span>{t('onboarding_s3_subtitle')}</span>
            <br />
            <span>{t('onboarding_s3_body')}</span>
            <br />
            <br />
            <div>
              <img
                className={'align-self-center'}
                style={{ marginRight: '50px' }}
                src={appScreen}
                // className="App-logo"
                alt="logo"
                width="200px"
              />
              <a
                href="https://apps.apple.com/be/app/ionnyk/id1502080765?l=fr"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ backgroundColor: 'black', marginRight: '50px' }}
                  src={iosLogo}
                  className="App-logo"
                  alt="logo ios"
                  width="150px"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.akommobile"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ backgroundColor: 'black' }}
                  src={androidLogo}
                  className="App-logo"
                  alt="logo android"
                  width="150px"
                />
              </a>
            </div>
          </Feedback>
        )}
      </FormCheck>
    </div>
  );
};
