import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Pages from './pages';

/* The following line can be included in your src/index.js or App.js file*/
// import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import './index.css';

import { Provider } from 'react-redux';
import { store } from './stores/store';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ProtectedRoute component={Pages} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
