import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from '../../hooks/useTranslation';
import logo from '../../assets/ionnyk_logo_black.png';
import _ from 'lodash';
import { ONE_DIGIT, ONE_LOWERCASE, ONE_UPPERCASE } from '../../utils/validation';
import { findGetParameter } from '../../utils';
import { ExitProps } from '../../components/ProtectedRoute/AuthProtector';
import {
  APIErrorClient,
  CUSTOM_CONFLICT_STATUS,
  FORBIDDEN_STATUS,
  UNAUTHORIZED_STATUS,
  UNKNOWN_ERROR_KEY,
} from '../../api/helper';
import { apiApplyResetPsw, apiStartResetPsw } from '../../api/resetPasswordApi';
import { useAppDispatch } from '../../hooks/reduxTKHooks';
import { logoutAsync } from '../../redux/signinSlice';

interface Props {}

// Blank page during public/private resolution
// FIXME appears also for login page ...
export const PublicResetPsw = ({ exitRouteFn }: Props & ExitProps) => {
  const t = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [submitOnce, setSubmitOnce] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordCheck, setPasswordCheck] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [sessionToken, setSessionToken] = useState<string>('');
  const [serverError, setServerError] = useState<any>(null);

  useEffect(() => {
    const token = findGetParameter('t');
    const email = findGetParameter('e');
    // console.log('PublicResetPsw mount',{token, email});
    if (token && email) {
      setEmail(email);
      setLoading(true);
      apiStartResetPsw({ token, email })
        // @ts-ignore
        .then((sessionToken) => setSessionToken(sessionToken))
        .catch((e) => {
          const error = e as APIErrorClient;
          console.warn('PublicResetPsw', error);
          switch (
            error?.statusCode ||
            UNKNOWN_ERROR_KEY // error === undefined if invalide
          ) {
            case UNAUTHORIZED_STATUS:
              alert(t('invalid_link'));
              setTimeout(() => exitRouteFn && exitRouteFn(), 750);
              break;
            case FORBIDDEN_STATUS:
              alert(t('expired_link'));
              setTimeout(() => exitRouteFn && exitRouteFn(), 750);
              break;
            default:
              alert(t(UNKNOWN_ERROR_KEY)); // FIXME
              console.log('PublicResetPsw', error);
              setTimeout(() => exitRouteFn && exitRouteFn(), 750);
          }
        })
        .finally(() => setLoading(false));
    } else {
      // FIXME better UX
      alert(t('no_token'));
      setTimeout(() => exitRouteFn && exitRouteFn(), 750);
    }
  }, []);

  const displayServerError =
    !!serverError &&
    (serverError.errorKey === UNKNOWN_ERROR_KEY || // always display it
      _.isEqual(password, serverError?.submittedData?.password));

  // FIXME factor out
  const invalidPassword =
    passwordCheck !== password ||
    _.isEmpty(password) ||
    password.length < 6 ||
    password.length > 32 ||
    !ONE_DIGIT.test(password) ||
    !ONE_LOWERCASE.test(password) ||
    !ONE_UPPERCASE.test(password);

  const submit = async () => {
    // console.log('submit', { email, sessionToken, password});
    setSubmitOnce(true);
    setServerError(undefined);
    if (invalidPassword) {
      return;
    }
    // server error : token invalid, expired, password do not match
    try {
      setLoading(true);
      await apiApplyResetPsw({ email, sessionToken, password });
      alert(t('success_reset')); // FIXME better UI pastille
      dispatch(logoutAsync()); // avoid 401 with former credentials
      setTimeout(() => exitRouteFn && exitRouteFn(), 750);
    } catch (e: any) {
      const error = e as APIErrorClient;
      switch (
        error?.statusCode ||
        UNKNOWN_ERROR_KEY // error === undefined
      ) {
        case UNAUTHORIZED_STATUS:
          alert(t('invalid_link'));
          setTimeout(() => exitRouteFn && exitRouteFn(), 750);
          break;
        case FORBIDDEN_STATUS:
          alert(t('expired_link'));
          setServerError(error);
          break;
        case CUSTOM_CONFLICT_STATUS:
          alert(t('psw_bad_format'));
          setServerError(error);
          break;
        default:
          console.warn('PublicResetPsw', error);
          setServerError(e ? e : { errorKey: UNKNOWN_ERROR_KEY, submittedData: password });
          alert(t('psw_unk_error'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container style={{ padding: '25px' }}>
      <Row style={{ marginBottom: '5rem' }}>
        <Col>
          <img
            style={{
              // image css centering :
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            src={logo}
            height="30rem"
            alt="Ionnyk logo"
          />
        </Col>
      </Row>
      {/*<Debug v={{displayServerError, serverError}} />*/}
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h3>{t('reset_psw_title')}</h3>
          <Form>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>{t('psw_label')}</Form.Label>
              <Form.Control
                isInvalid={submitOnce && invalidPassword}
                type="password"
                placeholder={t('psw_pl')}
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
              <Form.Text className="text-muted">{t('psw_hint')}</Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>{t('psw_repeat_label')}</Form.Label>
              <Form.Control
                isInvalid={submitOnce && invalidPassword}
                type="password"
                placeholder={t('psw_pl')}
                value={passwordCheck}
                onChange={(e) => setPasswordCheck(e.currentTarget.value)}
              />
              <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                {t('val_psw_strength')}
              </Form.Control.Feedback>
            </Form.Group>

            {!!displayServerError && (
              <Form.Text style={{ color: 'red', marginBottom: '25px' }}>
                {t(serverError.errorKey)}
              </Form.Text>
            )}
            <Button
              variant="primary"
              // type="submit" // No, otherwise HTTP submit form
              disabled={(invalidPassword && submitOnce) || loading}
              onClick={submit}
            >
              {loading ? t('page_loading') : t('submit_btn')}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
