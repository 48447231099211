import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { Container } from 'react-bootstrap';
import { findHashParameter } from '../../utils';
import { loadStripe } from '@stripe/stripe-js/pure';
import { localConfig } from '../../api/baseURLs';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(localConfig.STRIPE_KEY);

interface Props {}

// Stripe.js Checkout placeholder
export const PublicPayGate = ({}: Props) => {
  // const signupState = useAppSelector((state) => state.auth);
  const t = useTranslation();
  const [state, setState] = useState({ loading: true, error: '' });

  useEffect(() => {
    console.log('PublicPayGate mount');
    // const sessionId = findGetParameter('sessionId');
    const sessionId = findHashParameter('sessionId');
    if (sessionId) {
      (async () => {
        setState({ loading: true, error: '' });
        try {
          // When the customer clicks on the button, redirect them to Checkout.
          const stripe = await stripePromise;
          // @ts-ignore // LOST REACT SESSION ? FIXME
          const { error } = await stripe.redirectToCheckout({
            sessionId,
          });

          if (error) {
            // @ts-ignore
            setState({ loading: false, error: error.message });
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `error.message`.
          }
        } catch (error: any) {
          setState({ loading: false, error: error.message });
        }
      })();
    } else {
      const errorMsg = 'No sessionId found';
      setState({ loading: false, error: errorMsg });
    }
  }, []);

  return (
    <Container style={{ padding: '25px' }}>
      {/*<h2>{state.error ? state.error : t('page_loading')}</h2>*/}
      <h2>{state.error ? state.error : ''}</h2>
    </Container>
  );
};
