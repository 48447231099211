import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
  User,
  UserInfo,
  IdTokenResult,
  onIdTokenChanged,
  sendPasswordResetEmail,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import _ from 'lodash';
import { onBearerTokenRefresh } from './api';
import { MFARequiredError, PasswordError, ReloginRequired } from './AuthError';
import { apiUrl, localConfig } from './baseURLs';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_GCP_API_KEY,
  authDomain: process.env.REACT_APP_API_GCP_AUTH_DOMAIN,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
auth.tenantId = localConfig.TENANT ?? '';
console.info('Firebase tenantId from: ', { apiUrl }, !!auth.tenantId);

export type FirebaseUser = Pick<User, 'uid' | 'email' | 'emailVerified'> & {
  idToken: string;
  jwtToken: string;
  expirationTime: string;
};

// Global listener for token refresh or sign in/sign out event
const REFRESH_RATE = 1000 * 60 * 60 - 1000 * 60; // 1 hour - 1 min
onIdTokenChanged(auth, async (newUser: User | null) => {
  if (newUser) {
    console.info('onIdTokenChanged - signed In', newUser.uid);
    const newFirebaseUser: FirebaseUser = await user2FirebaseUser(newUser);
    onBearerTokenRefresh(newFirebaseUser.idToken);
    setTimeout(async () => await newUser.getIdToken(true), REFRESH_RATE);
  } else {
    console.info('onIdTokenChanged - signed Out');
  }
});

const user2FirebaseUser = async (user: User): Promise<FirebaseUser> => {
  user?.providerData?.map((userInfo: UserInfo) => console.info({ userInfo }));
  const idToken = await user.getIdToken(); // args = forceRefresh: boolean
  const idTokenResult: IdTokenResult = await user.getIdTokenResult();
  const jwtToken = idTokenResult.token;
  const expirationTime = idTokenResult.expirationTime;
  const firebaseUser: FirebaseUser = {
    ..._.pick(user, ['uid', 'email', 'emailVerified']),
    idToken,
    jwtToken,
    expirationTime,
  };
  return firebaseUser;
};

export const firebaseSignOut = async () => {
  const { currentUser } = auth;
  if (currentUser) {
    console.info('firebaseSignOut: currentUser', currentUser.uid);
    await signOut(auth);
  }
};

export const firebaseSignIn = async (email: string, password: string) => {
  await setPersistence(auth, browserLocalPersistence);
  try {
    const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
    const user: User = userCredential.user;
    const firebaseUser: FirebaseUser = await user2FirebaseUser(user);
    console.info('signInWithEmailAndPassword success', firebaseUser.uid);
    return firebaseUser;
  } catch (e: any) {
    console.warn('signInWithEmailAndPassword error', e);
    if (e?.code === 'auth/multi-factor-auth-required') {
      console.info('The user is a multi-factor user. Second factor challenge is required.');
      throw new MFARequiredError();
    } else if (e?.code === 'auth/wrong-password') {
      console.warn('wrong password');
      throw new PasswordError('Wrong password');
    } else {
      throw e;
    }
  }
};

export const firebaseRestoreSignin = async () => {
  await auth.authStateReady();
  const { currentUser } = auth;
  if (currentUser) {
    console.info('firebaseSignIn: currentUser restored', currentUser.uid);
    const firebaseUser: FirebaseUser = await user2FirebaseUser(currentUser);
    return firebaseUser;
  } else {
    throw new ReloginRequired();
  }
};

// FIXME Backend will not change the stored psw in db
// https://support.google.com/firebase/answer/7000714
export const firebaseForgotPassword = async (email: string) =>
  await sendPasswordResetEmail(auth, email);
