export interface RequestStatus {
  loading: boolean;
  serverError?: APIErrorClient;
}
export const initialRequestStatus: RequestStatus = {
  loading: false,
  serverError: undefined,
};

// http errors
export const SERVER_ERROR_STATUS = 500;
export const CUSTOM_CONFLICT_STATUS = 409;
export const CLIENT_BAD_REQUEST_STATUS = 400;
export const CLIENT_BAD_REQUEST_STATUS_STRING = 'CLIENT_BAD_REQUEST';
export const UNAUTHORIZED_STATUS = 401;
export const NOT_FOUND_STATUS = 404;
export const FORBIDDEN_STATUS = 403;

// FIXME common-ts
export const UNKNOWN_ERROR_KEY = 'UNKNOWN_ERROR_KEY';
export interface APIErrorClient {
  errorKey: string;
  submittedData: any;
  statusCode?: any;
  desc?: string;
}
// FIXME request id
export const handleNetworkError = (
  src: string,
  e: any,
  submittedData: any = {},
  additionalClientErrors: number[] = [], // any error status we want to display in custom fashion
  silent: boolean = false
) => {
  console.warn('Network error for ' + src, {
    // ...e,
    // client config
    method: e.config?.method,
    fullURL: e.config && `${e.config.baseURL}${e.config.url}`,
    params: e.config?.params,
    body: e.config?.body,
    // http layer
    status: e.response?.status,
    statusText: e.response?.statusText,
    // Server specific
    error: e.response?.data?.error,
    code: e.response?.data?.code,
    description: e.response?.data?.description,
    // axios error layer
    axiosError: e.name,
    axiosErrorMsg: e.message,
  });

  if (!!e?.axiosErrorMsg?.startsWith('timeout')) {
    console.warn('Timeout encountered');
    return {
      errorKey: UNKNOWN_ERROR_KEY,
      statusCode: SERVER_ERROR_STATUS, // FIXME better ?
      submittedData,
    } as APIErrorClient;
  }

  if (e?.response?.status === CUSTOM_CONFLICT_STATUS && e?.response?.data) {
    // Known Server Error to handle from client
    return {
      errorKey: e.response.data.code,
      statusCode: e.response.status,
      submittedData,
    } as APIErrorClient;
  } else if (e?.response?.status === CLIENT_BAD_REQUEST_STATUS) {
    // General Server Error to handle from client
    return {
      errorKey: CLIENT_BAD_REQUEST_STATUS_STRING,
      statusCode: e?.response?.status,
      submittedData,
    } as APIErrorClient;
  } else if (additionalClientErrors?.includes(e?.response?.status)) {
    return {
      errorKey: e?.response?.data?.error || e?.error,
      statusCode: e?.response?.status,
      submittedData,
    } as APIErrorClient;
  } else {
    // Unknown Server Error
    !silent && alert(e?.message); // undefined is returned
  }
};

// redux helpers - FIXME TO COMMON
