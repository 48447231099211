import { Nav } from 'react-bootstrap';
import { sortedPrivateRoutes } from '../../pages/routes';
import { NavLink } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';

interface Props {
  hasLogout?: boolean;
  hasCloseButton?: boolean;
  onClose?: () => void;
}

export const SideBar = ({ hasCloseButton = false, onClose }: Props) => {
  const t = useTranslation();

  return (
    <Nav
      style={{
        borderStyle: 'solid',
        borderWidth: 1,
        height: '100%',
        padding: '20px',
      }}
      className="flex-column flex-fill bg-dark"
      onSelect={(selectedKey) => console.log(`selected ${selectedKey}`)}
    >
      {hasCloseButton && (
        <Nav.Link href="#" onClick={() => onClose && onClose()}>
          {t('close_btn')} ×
          <hr />
        </Nav.Link>
      )}
      {sortedPrivateRoutes.map((r: any) => (
        <NavLink
          key={r.key}
          className="nav-link"
          style={{ fontSize: 25, fontFamily: 'Montserrat', color: 'white' }}
          activeStyle={{
            fontWeight: 'bold',
          }}
          exact
          to={r.path}
        >
          {t(r.label)}
        </NavLink>
      ))}
    </Nav>
  );
};
