import { SubsAction, SubsActionDTOIn, SubscriptionItem } from '../types';
import { authApi} from '../../api/api';
import {  handleNetworkError, UNAUTHORIZED_STATUS } from '../../api/helper';
import { store } from '../../stores/store';
import { logoutAsync } from '../signinSlice';


export const fetchSubs = async (): Promise<Partial<SubscriptionItem>[]> => {
  console.info('fetchSubs API');
  return (await authApi.get(`subscriptions`))?.data;
};

export const updateSubs = async (
  subscriptionType: string,
  action: SubsAction,
  pricingId?: string
): Promise<SubsActionDTOIn> => {
  console.info('updateSubs API', {
    subscriptionType,
    action,
    pricingId,
  });
  return (
    await authApi
      .post(`subscriptions/${subscriptionType}`, {
        action,
        pricingId,
      })
      .catch((e) => {
        const error = handleNetworkError('updateSubs', e, { action, pricingId }, [
          UNAUTHORIZED_STATUS,
        ]);
        if (error?.statusCode === UNAUTHORIZED_STATUS) {
          store.dispatch(logoutAsync());
        } else {
          throw error;
        }
      })
  )?.data;
};
