import { useCallback, useEffect, useState } from 'react';

export function useURLParams(paramName: string) {
  const location = window.location;
  const [result, setResult] = useState('');

  const detectUrlParams = useCallback(() => {
    const query = new URLSearchParams(location.search);
    const search = query.get(paramName);
    if (search) {
      setResult(search);
    }
  }, [location, paramName]);

  useEffect(() => {
    detectUrlParams();
  }, [location, paramName]);

  return {
    [paramName]: result,
  };
}
