import { productInfos } from '../pages/Subs/constants';
// import {UserProfile} from 'common-ts'
export interface UserProfile {
  firstName: string;
  lastName: string;
  email: string; // email
  phone2fa?: string; // phone number for 2FA
  captcha?: string;
}
export interface UserProfileDTOIn extends UserProfile {}

export type UpdateProfileType = 'DETAIL' | 'EMAIL' | 'PASSWORD' | 'PHONE_2FA';

export interface UserProfileDTOOut extends Partial<UserProfile> {
  type: UpdateProfileType; // mandatory
  password?: string;
  newPassword?: string;
  newPhone2fa?: string;
}

export enum SubsAction {
  NONE = '',
  SUBSCRIBE = 'SUBSCRIBE',
  SUBSCRIBE_TRIAL = 'SUBSCRIBE_TRIAL',
  UPGRADE_TRIAL = 'UPGRADE_TRIAL',
  MANAGE = 'MANAGE',
  USER_ACTION_REQUIRED = 'USER_ACTION_REQUIRED',
  ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD', // legacy
}

export const ActionMap = {
  [SubsAction.NONE]: 'ACTIVE',
  [SubsAction.SUBSCRIBE]: 'SUBSCRIBE',
  [SubsAction.SUBSCRIBE_TRIAL]: 'TRY IT FOR FREE*',
  [SubsAction.USER_ACTION_REQUIRED]: 'ACTION REQUIRED',
  [SubsAction.ADD_PAYMENT_METHOD]: 'ADD PAYMENT',
  [SubsAction.MANAGE]: 'MANAGE',
};

export const EndReasonMap = {
  CANCELLATION: 'Cancelled',
  EXPIRATION: 'Payement issue',
  TRIAL_EXPIRATION: 'Trial expired',
  UPGRADE: '', // Only if
} as any;

// FIXME enum when backend done
export type EndReasonType =
  | 'CANCELLATION'
  | 'EXPIRATION' // pay required
  | 'TRIAL_EXPIRATION'
  | 'UPGRADE'
  | 'PENDING' // FIXME
  | 'TODO' // FIXME
  | string;

export interface UserSubscription {
  ref: string;
  active: boolean;
  startDate?: string | null; //current subscription creation date
  endDate?: string | null; // always null for the current subscription
  endReason?: EndReasonType; // end status for lastSub
  nextBillingDate?: string | null;
  cancellationDate?: string | null;
  trialExpirationDate?: string | null;
}

//FIXME keep?
export enum SubsType {
  DISCOVERY = 'discovery',
  TASTE = 'taste-of-ionnyk',
}

//FIXME keep?
export enum PricingPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export interface PricingObj {
  price: number;
  pricingId: string;
}

export interface SubscriptionItem {
  subscriptionType: SubsType | string; // FIXME freemium ? premium ?
  // subscriptionName: {
  //   en: string;
  //   fr?: string;
  //   nl?: string;
  // },
  actions: Array<SubsAction>;
  pricings?: Record<PricingPeriod | string, PricingObj> | null;
  stripeObject?: {
    // for debug purpose, optional
    product_id: string;
    pricing_id: string;
  } | null;
  trialDuration: number; //number of days
  currentSubscription?: UserSubscription;
  lastSubscription?: UserSubscription;
}

export interface SubsActionDTOIn {
  redirectUrl?: string;
}

export interface SubsActionDTOOut {
  action: SubsAction;
}

// FIXME keep?
export interface SubLogin {
  subscriptionType: SubsType;
  // subscriptionName: {
  //   en: string;
  //   fr?: string;
  //   nl?: string;
  // },
  startDate: string;
  status:
    | 'ACTIVE'
    | 'NEW'
    | 'PAYMENT_PENDING'
    | 'DONE'
    | 'TRIAL'
    | 'PAYMENT_ISSUE'
    | 'CANCEL_REQUESTED'; // NEW, PAYMENT_PENDING, ACTIVE, DONE, TRIAL, PAYMENT_ISSUE, CANCEL_REQUESTED
}
export interface AuthUserDTOIn {
  id: number;
  roles: string[];
  email: string;
  emailConfirmed?: boolean;
  lastLoginDate?: Date;
  subscription?: SubLogin; // FIXME Pick<UserSubscription, 'ref' | 'subscriptionType' | 'startDate'>,
}

export interface Credentials {
  email: string;
  password: string;
}

export interface PromotionDTOIn {
  promotionType: string; // PREMIUM_SUBSCRIPTION
}

export type NewUser = UserProfile & Credentials;

export type ProductType = SubscriptionItem & (typeof productInfos)[SubsType.DISCOVERY]; // RICHER
