import React, { useEffect, useRef, useState } from 'react';
import { Container, Toast } from 'react-bootstrap';
import { useTranslation } from '../../hooks/useTranslation';
import { ProductList } from './components/ProductList';
import { ProductType, SubsAction, SubscriptionItem, SubsType } from '../../redux/types';
import { fetchSubs, updateSubs } from '../../redux/subsSlice';
import { productInfos } from './constants';
import { TKeys } from '../../i18n/en';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

export const POLLING_STATUS_FREQ = 3000;
export const MAX_POLLING = 10;

export const Subs = () => {
  const t = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  let [message, setMessage] = useState('');
  let [errorMsg, setErrorMsg] = useState('');
  let [customerId, setCustomerId] = useState(''); // FIXME redux
  let [subs, setSubs] = useState<any>(null); // FIXME redux
  let [loading, setLoading] = useState(false);

  const [unlocking, setUnlocking] = useState(false);
  const unlockPollingCount = useRef<number | null>(null); // true if polling is ongoing
  const unlockPollingInterval = useRef<number | null>(null); // true if polling is ongoing

  // FIXME using redux useAppDispatch, useAppSelector,authState.subscription,
  const loadSubsDetails = async (isTabletOrMobile: boolean = false) => {
    setLoading(true);
    try {
      const userSubs = await fetchSubs();
      const localSubs: ProductType[] = Object.values(productInfos)
        .filter((s) =>
          [SubsType.TASTE, SubsType.DISCOVERY].includes(s.subscriptionType as SubsType)
        )
        .sort(
          // subs first in mobile
          // @ts-ignore
          (a: ProductType, b: ProductType) =>
            isTabletOrMobile ? b.order - a.order : a.order - b.order
        )
        .map((p) => {
          const targetUserSub = userSubs.find(
            (us: Partial<SubscriptionItem>) => us.subscriptionType === p.subscriptionType
          );
          return {
            ...p,
            ...targetUserSub,
          } as ProductType;
        });
      setSubs(localSubs);
      console.info({ localSubs });
      setLoading(false);
      return localSubs;
    } catch (err) {
      console.warn(err);
      setLoading(false);
    }
  };

  function startPollingForSub(isLegacy: boolean = false) {
    // FIXME show yearly/monthly selected
    // FIXME update remaining days and remove from title
    // FIXME make special notif to restart app
    // FIXME after polling mobile, show your active first

    console.info('detectedStripeRedirection ' + Location, 'start polling ...');
    setUnlocking(true);
    unlockPollingCount.current = MAX_POLLING; // polling has started
    // @ts-ignore
    unlockPollingInterval.current = setInterval(async () => {
      console.info('>>> Tick for polling');
      if (unlockPollingCount.current === null || unlockPollingCount.current === undefined) {
        console.warn('Null/undef unlockPollingCount');
        return;
      }
      if (unlockPollingCount.current === 0) {
        console.warn('LOG_TAG', 'Max polling reached', unlockPollingCount.current, subs);
        clearInterval(unlockPollingInterval.current || undefined);
        setUnlocking(false);
        setErrorMsg('Could not receive a server update yet. Please refresh your subs later');
        // remove polling params
        history.replace(location.pathname);
      } else if (unlockPollingCount.current > 0) {
        try {
          console.info('LOG_TAG', 'Polling ', unlockPollingCount.current);
          const localSubs = await loadSubsDetails();
          const discoverySub = localSubs?.find(
            (s) => s?.subscriptionType === SubsType.DISCOVERY.toString()
          );

          if (isLegacy && !discoverySub?.actions.includes(SubsAction.ADD_PAYMENT_METHOD)) {
            console.info('PROCESSED LEGACY!', unlockPollingCount.current);
            clearInterval(unlockPollingInterval.current || undefined);
            unlockPollingCount.current = null;
            setUnlocking(false);
            console.info('Polling processed legacy');
            // remove polling params
            history.replace(location.pathname);
          }

          if (!!discoverySub?.currentSubscription?.active) {
            console.info('PROCESSED !', unlockPollingCount.current);
            clearInterval(unlockPollingInterval.current || undefined);
            unlockPollingCount.current = null;
            setUnlocking(false);
            console.info('Polling processed ');
            // remove polling params
            history.replace(location.pathname);
          } else {
            console.info('LOG_TAG', 'Not yet activated', {
              discoverySub,
              localSubs,
            });
            unlockPollingCount.current = Math.max((unlockPollingCount.current || 0) - 1, 0);
          }
        } catch (e) {
          console.warn('Polling', e);
          unlockPollingCount.current = Math.max((unlockPollingCount.current || 0) - 1, 0);
          // remove the polling params
          history.replace(location.pathname);
        }
      }
    }, POLLING_STATUS_FREQ);

    return () => {
      console.info('detectedStripeRedirection ' + Location);
      clearInterval(unlockPollingInterval.current || undefined);
    };
  }

  const detectedStripeRedirection = (location: Location) => {
    // const query = new URLSearchParams(window.location.search);
    const query = new URLSearchParams(location.search);

    if (query.get('success')) {
      setMessage('Your subscription has been successfully acquired !');
      const isLegacy = !!query.get('legacy');
      return startPollingForSub(isLegacy);
    }

    if (query.get('cancelled')) {
      setMessage("Order cancelled -- continue to shop around and checkout when you're ready.");
    }
  };

  useEffect(() => {
    loadSubsDetails(isTabletOrMobile).then(console.info);
  }, [isTabletOrMobile]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    detectedStripeRedirection(location as any);
  }, [location]);

  const doAction = async (subscriptionType: string, action: SubsAction, pricingId?: string) => {
    setLoading(true);
    try {
      let { redirectUrl = '' } = await updateSubs(subscriptionType, action, pricingId);
      const hostedStartUrl = window.location.origin + '/';
      console.info(redirectUrl, 'updateSubs', hostedStartUrl);
      redirectUrl = redirectUrl?.replace('https', 'http'); // FIXME remove
      if (redirectUrl.startsWith(hostedStartUrl)) {
        // FIXME use URL parser (portal vs www.portal)
        // FIXME subscribe_stripe and manage_stripe never used
        console.info(redirectUrl, 'starts with portal');
        const newURL = redirectUrl.split(hostedStartUrl)[1];
        console.info(redirectUrl, newURL);
        const [path, param] = newURL.split('#');
        console.info(redirectUrl, path, param);
        switch (path) {
          case 'subscribe_stripe':
            setLoading(false);
            console.info('subscribe_stripe', { param });
            break;
          case 'manage_stripe':
            setLoading(false);
            console.info('manage_stripe', { param });
            break;
        }
      } else {
        window.location.replace(redirectUrl);
        setLoading(false); // surely useless
      }
    } catch (e: any) {
      // alert(e.toString())
      console.log('>>> Failed action: ', e, e.toString(), e.message, e.status);
      console.warn('Network error for ' + 'src', {
        // ...e,
        // client config
        method: e.config?.method,
        fullURL: e.config && `${e.config.baseURL}${e.config.url}`,
        params: e.config?.params,
        body: e.config?.body,
        // http layer
        status: e.response?.status,
        statusText: e.response?.statusText,
        // Server specific
        error: e.response?.data?.error,
        code: e.response?.data?.code,
        description: e.response?.data?.description,
        // axios error layer
        axiosError: e.name,
        axiosErrorMsg: e.message,
      });
      // FIXME better error handling (code, etc.)
      setErrorMsg(
        `HTTP ${e.response?.status}: ${e.response?.data?.description} [code = ${e.response?.data?.code}]`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container style={{ padding: '15px' }}>
      <Toast
        style={{
          position: 'absolute',
          top: 5,
          right: 5,
          zIndex: 999,
          opacity: 1,
        }}
        onClose={() => {
          setMessage('');
        }}
        show={!!message}
        delay={2000}
        autohide
      >
        <Toast.Header>
          <strong className="mr-auto">{t('VOUCHER_SUCCESS')}</strong>
        </Toast.Header>
        <Toast.Body>{t(message as TKeys)}</Toast.Body>
      </Toast>
      <Toast
        style={{
          position: 'absolute',
          top: 5,
          right: 5,
          zIndex: 999,
          opacity: 1,
          // width: '400px' FIXME
        }}
        onClose={() => {
          setErrorMsg('');
        }}
        show={!!errorMsg}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <strong className="mr-auto">{'Something wrong happened'}</strong>
        </Toast.Header>
        {/* FIXME key from e.message ?*/}
        <Toast.Body>{t(errorMsg as TKeys)}</Toast.Body>
      </Toast>
      <ProductList
        loading={loading || unlocking}
        doAction={doAction}
        products={subs}
        key={'m1'}
        customer_id={customerId}
        setCustomerId={setCustomerId}
      />
    </Container>
  );
};
