import React from 'react';
import { Container } from 'react-bootstrap';
import { OnboardingStep, OnboardingTodo } from '../../components/OnboardingTodo/OnboardingTodo';
import { useAppSelector } from '../../hooks/reduxTKHooks';
import { useTranslation } from '../../hooks/useTranslation';

interface Props {}

export const Home = ({}: Props) => {
  const signupState = useAppSelector((state) => state.auth);

  const t = useTranslation();

  return (
    <Container style={{ padding: '25px' }}>
      <h2>{t('onboarding_title')}</h2>
      <OnboardingTodo
        progress={{
          [OnboardingStep.EMAIL_VALIDATION]: !!signupState.emailConfirmed,
          [OnboardingStep.VOUCHER_PROMPT]: !!signupState?.subscription?.startDate, // FIXME a skip must be logged to server
          [OnboardingStep.APP_INSTALLATION]: !!signupState?.lastLoginDate, // FIXME get app install endpoint
        }}
      />
    </Container>
  );
};
