import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authApi } from '../../api/api';
import { PromotionDTOIn } from '../types';
import { logoutAsync } from '../signinSlice';
import {
  handleNetworkError,
  initialRequestStatus,
  UNAUTHORIZED_STATUS,
  UNKNOWN_ERROR_KEY,
} from '../../api/helper';

export const burnVoucher = createAsyncThunk(
  'voucher/use',
  async (voucherCode: string, thunkAPI) => {
    try {
      const response = await authApi.post('vouchers/', { voucherCode });
      console.log('burnVoucher', response.data);
      return response.data as PromotionDTOIn; // FIXME unused yet
    } catch (e) {
      const error = handleNetworkError('burnVoucher', e, { voucherCode }, [UNAUTHORIZED_STATUS]);
      if (error?.statusCode === UNAUTHORIZED_STATUS) {
        thunkAPI.dispatch(logoutAsync());
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const voucherSlice = createSlice({
  name: 'voucher',
  initialState: { ...initialRequestStatus },
  reducers: {},
  extraReducers: {
    // @ts-ignore
    [burnVoucher.pending]: (state, action) => ({
      loading: true,
      serverError: undefined,
    }),
    // @ts-ignore
    [burnVoucher.fulfilled]: (state, action) => {
      return {
        loading: false,
        serverError: undefined,
      };
    },
    // @ts-ignore
    [burnVoucher.rejected]: (state, action) => {
      if (action.payload) {
        return { loading: false, serverError: action.payload };
      } else {
        return { loading: false, serverError: { errorKey: UNKNOWN_ERROR_KEY } };
      }
    },
  },
});
