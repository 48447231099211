import { APIErrorClient } from './helper';

// Firebase constants for APIErrorClient
export const NO_ID_TOKEN = 'NO_ID_TOKEN';
export const MFA_REQUIRED = 'MFA_REQUIRED';
export const MISSING_USER_ERROR = 'MISSING_USER_ERROR';
export const MISSING_PHONE_2FA = 'MISSING_PHONE_2FA';
export const MISSING_VALIDATION_ID = 'MISSING_VALIDATION_ID';
export const WRONG_PASSWORD = 'WRONG_PASSWORD';
export const EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED';
export const RELOGIN_REQUIRED = 'RELOGIN_REQUIRED';

export class AuthError extends Error implements APIErrorClient {
  errorKey: APIErrorClient['errorKey'];
  statusCode?: APIErrorClient['statusCode'];
  submittedData: any;
  desc?: string;
  originalError?: any;
  constructor(errorKey: APIErrorClient['errorKey']) {
    super(errorKey);
    this.errorKey = errorKey;
    this.submittedData = null;
  }
}
export class MFARequiredError extends AuthError {
  constructor() {
    super(MFA_REQUIRED);
  }
}
export class MissingUserError extends AuthError {
  constructor() {
    super(MISSING_USER_ERROR);
  }
}
export class MissingPhone2FAError extends AuthError {
  constructor() {
    super(MISSING_PHONE_2FA);
  }
}
export class MissingValidationIdError extends AuthError {
  constructor() {
    super(MISSING_VALIDATION_ID);
  }
}
export class PasswordError extends AuthError {
  constructor(desc?: string) {
    super(WRONG_PASSWORD);
    this.desc = desc;
  }
}
export class ReloginRequired extends AuthError {
  constructor(desc?: string) {
    super(RELOGIN_REQUIRED);
    this.desc = desc;
  }
}
export class EmailValidationRequired extends AuthError {
  constructor(desc?: string) {
    super(EMAIL_NOT_VERIFIED);
    this.desc = desc;
  }
}
